new WOW({ offset: 75 }).init();

$(document).ready(function () {
	$(window).resize(initResize);




	if ($("#BannerPanel").length) {
		$("#LeftKids").addClass("wow slideInUp");
	}

	// $("#TopLinks > ul > li > a").addClass("wow fadeIn");
	$("#TopLinks > ul > li > a").each(function (index, value) {
		$(this).attr("data-wow-delay", index / 10 + "s");
		$(this).addClass("wow fadeIn");
	});

	$("#SocialMediaLinks a").each(function (index, value) {
		$(this).attr("data-wow-delay", index / 10 + "s");
		$(this).addClass("wow flipInY");
	});

	$(".dropdown-menu a").addClass("wow fadeIn");

	var imgWidth = $(".imgPanel").width();

	$("#HomepageGallery .Previous").click(function (e) {
		var img = $("#HomepageGalleryInner div").first();
		img.animate({ width: 0 }, 1000, "linear", function () {
			img.remove();
			$("#HomepageGalleryInner").append(img);
			img.width(imgWidth);
		});
		e.preventDefault();
		FixImages();
		return false;
	});

	$("#HomepageGallery .Next").click(function (e) {
		var img = $("#HomepageGalleryInner div").last();
		img.width(0);
		img.remove();
		$("#HomepageGalleryInner").prepend(img);
		img.animate({ width: imgWidth }, 1000, "linear");
		e.preventDefault();
		FixImages();
		return false;
	});

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$(".castleTitlePanel").sameHeight();
		$(".Testimonial").sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

});






function initResize() {
	imgWidth = $(".imgPanel").width();
}
